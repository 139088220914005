import React from 'react';
import styled from 'styled-components';


const InputInfo = styled.div`
    background-color:#fff;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px #ccc;
  
  

`;


class AdflowInfo extends React.Component {
    constructor(){
        super();
     
    }

    render() {
        return <React.Fragment>
            <div className='col-12 col-lg-4 mb-3 d-none d-md-none d-lg-block'>
            <InputInfo style={{margin: "0rem 1rem"}}>
                    {this.props.children}
            </InputInfo> 
            </div>

            {/* modal */}
            <div className='col-6 d-block d-sm-block d-md-block d-lg-none'>
            <div class="modal fade col-6" id={this.props.id} tabindex="-1" aria-labelledby={this.props.aria} aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div className='col-12 col-lg-4 mt-3' >
                        <InputInfo style={{ margin: "0rem 1rem" }}>
                     
                        {this.props.children}

                        </InputInfo>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-blue" data-bs-dismiss="modal">Stäng infobox</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </React.Fragment>
        {/* end of modal */}

       
    }
}


export default AdflowInfo;