
import React from 'react';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import styled from 'styled-components';
import AdflowInfo from './AdflowInfo';

const Title = styled.h3`
    color: rgb(52, 93, 179) !important;
    font-size: 0.9vw;
    `;

const Option = styled.div`
    background-color:#fff;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0px 0px 2px 1px #ccc;
    margin-bottom:10px;

`;

const Paragraph = styled.p`
  font-size:14px
`;

export {Title, Option, Paragraph}

class AdFlow1 extends React.Component {
    constructor(){
        super();
        this.delivYear = React.createRef();

        this.state = {
            ad_types: [],
            arrangement: []
        }

    }
    
    clear_errors(){
      this.props.removeError("start_time");
      this.props.removeError("end_time");
      this.props.removeError("felling_start_time");
      this.props.removeError("felling_end_time");

    }

    componentDidMount(){
      gtag('event', 'Created ad draft', {});
      gtag("event", "generate_lead", {
          currency: "SEK",
          value: 585
        });
    }

    render() {
      if (this.props.init_load){
          return null;
      }else{ 
        return <div>
        {/* 1st input form */}
            <div className='input-group d-flex flex-row justify-content-between'>
                <div className='col-12 col-lg-8'> 
                <Option>
                    <div className = "row mb-3">
                    <div className = "col-12 col-lg-6 mt-2">

                  {/* Icon for infobox on medium to small devices */}
                    <div className='d-flex flex-row justify-content-end'>
                        <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step1InputForm1"></i>
                    </div>

                        <Title className="text-md-start">Vad vill du göra?</Title>
                            <VBDropdown
                                options={this.props.arrangement} 
                                onChange={(vals) => {this.setState({arrangement:vals})}} 
                                />
                            </div>
                            <div className = "col-12 col-lg-6 mt-2">
                                <Title className="text-md-start ">Fastighetsbeteckning/fastighetsbeteckningar</Title>

                            <VBInput 
                                type={"text"}
                                name="description_of_property" 
                                // validation={() => {return ''}}    
                                validation={() => {
                                    if (length !==0){
                                        return false;
                                    }else{
                                        return "Detta måste fyllas i";
                                    }
                                    }}
                                
                                />
                            </div>
                        </div>
                        </Option>   
                    </div>

                {/* {/* Info box content */}
                    <AdflowInfo id="Step1InputForm1" aria="Step1InputForm1"> 
                        <Paragraph>
                        <p class='lh-base px-2 py-2'>
                            <strong>Slutavverkning/<br/>föryngringsavverkning:</strong> <br/>Betyder samma sak och innebär att man avverkar beståndet i sin helhet.I skogsbruksplanen brukar områden som skall Slutavverkas vara markerade som S1 eller S2.<br/><br/>
                            <strong>Gallring:</strong> I skogsbruk innebär att man avverkar vissa träd i ett bestånd men låter majoriteten stå kvar. I skogsbruksplanen brukar områden som skall gallras vara markerade som G1 eller G2.<br/><br/>
                            <strong>Övrig avverkning:</strong> Har du träd som fallit ner av vinden eller en speciell typ av avverkning som du vill göra väljer du detta alternativ.
                        </p>
                        </Paragraph>
                    </AdflowInfo>
                </div>
                {/* end of 1st input form */}
 

                 {/* 2nd input form */}
                    <div className='input-group d-flex flex-row justify-content-between'>
                    <div className='col-12 col-lg-8 mb-3'> 
                    <Option>
                        <div className = "row mb-3">
                        <div className = "col-12 col-lg-12 mt-2">

                    {/* Icon for infobox on medium to small devices */}
                        <div className='d-flex flex-row justify-content-end'>
                            <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step1InputForm2"></i>
                        </div>

                        <Title className="text-md-start ">Hur vill du sälja ditt virke?</Title>
                        <VBDropdown
                            options={this.props.adTypes}
                            validation={() => {return ''}}
                            onChange={(vals) => {this.setState({ad_types:vals})}} 
                        />
                        </div> 
                        </div>
                    </Option> 
                </div>

                {/* info box content */}
                <AdflowInfo id="Step1InputForm2" aria="Step1InputForm2"> 
                    <Paragraph>
                    <p class='lh-base px-2 py-2'>
                    <strong>Ange den försäljningsform du vill sälja ditt virke på.</strong> Är du osäker hjälper vi dig gärna med att förklara vilka olika alternativ som finns.<br/><br/>
                    <strong>Avverkningsuppdrag:</strong> Är den vanligaste formen att sälja virke på. Här får du antingen anbud som pris per sortiment eller prislista + eventuella tillägg. Kostnaden för att avverka och ta hand om ditt virke får du som en fast avverkningskostnad eller som en lista baserad medelstam.<br/><br/>
                    <strong>Leveransrotköp:</strong>  Med denna försäljningsform får du ett nettopris inkl. avverkningskostnader oavsett kvalité och sortiment. Exempel 450kr/m3fub.<br/><br/>
                    <strong>Leveransvirke:</strong> När du eller någon annan avverkar och skogsbolaget köper virket vid bilväg. Här får du också oftast prislista + eventuella tillägg eller fast pris per sortiment.<br/><br/>
                    <strong>Rotpost:</strong> När skogen är stämplad av en stämplingsförrätare och en stämplingslängd finns. Då får du ett total pris inkl. avverkningskostnad exempel (1 000 000 kr).
                    </p>
                    </Paragraph>
                </AdflowInfo>
                </div>
            {/* end of 2nd input form */}


            {/*3rd input form*/}
            <div className='input-group d-flex flex-row justify-content-between'>
                <div className='col-12 col-lg-8'> 
                    <Option>
                    <div className = "row mb-3">
                    <div className = 'col-12 col-lg-6 mb-3 mt-2'>
                
                {/* Icon for infobox on medium to small devices */}
                    <div className='d-flex flex-row justify-content-end'>
                        <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step1InputForm3"></i>
                    </div>

                    <Title className='text-md-start'>Annonseringperiod</Title>
                    <VBInput          
                        type={'date'}
                        onChange={()=>{this.props.removeError("end_time")}}
                        label={<h6>Från</h6>}
                        />
                    </div>
                    <div className='col-12 col-lg-6' style={{marginTop:"2rem"}}>
                    <VBInput          
                        type={'date'}
                        onChange={()=>{this.props.removeError("felling_start_time")}}
                        label={<h6>till</h6>}
                        />
                        </div>  
                    </div> 
                    </Option> 
                </div>
 
               {/* info box content */}
                <AdflowInfo id="Step1InputForm3" aria="Step1InputForm3"> 
                    <Paragraph>
                        <p class='lh-base px-2 py-2'>
                        <strong>Annonseringsperiod: </strong>Här väljer du hur lång tid din annons ska vara publicerad på Virkesbörsen. Detta är den tid som virkesköparna har på sig att lämna anbud. Vi rekommenderar att annonsen ska ligger uppe i 3-4 veckor så att köparna har tillräckligt med tid.
                        </p>
                    </Paragraph>
                    </AdflowInfo>
                </div>
            {/* end of 3rd input form */}
            
            {/* 4rth input form */}
                <div className='input-group d-flex flex-row justify-content-between'>
                    <div className='col-12 col-lg-8 mb-3'> 
                        <Option>
                        <div className = "row mb-3">
                        <div className ='col-12 col-lg-6 mb-3 mt-2'>
                        
                    {/* Icon for infobox on medium to small devices */}
                        <div className='d-flex flex-row justify-content-end'>
                            <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step1InputForm4"></i>
                        </div>

                        <Title className="text-md-start">Avverkningsperiod</Title>
                        <VBInput          
                            type={'date'}
                            validation={()=>{
                            return this.props.removeError("felling_end_time")}}
                            label={<h6>Från</h6>}
                            />
                        </div>
                            <div className='col-12 col-lg-6' style={{marginTop:"2rem"}}>
                            <VBInput          
                                type={'date'}
                                label={<h6>till</h6>}
                                />
                            </div>  
                        </div> 
                        </Option> 
                    </div>
                   
                   {/* info box content */}
                    <AdflowInfo id="Step1InputForm4" aria="Step1InputForm4"> 
                        <Paragraph>
                        <p class="lh-base px-2 py-2">
                            <strong>Avverkningsperiod: </strong>Här anger du hur lång tid köparen som du eventuellt väljer har på sig att avverka skogen. Vi rekommenderar 2 till 5 år.
                        </p>
                        </Paragraph>
                        </AdflowInfo>
                    </div>
                {/* end of 4rth input form */}

    
                {/* 5th input form */}
                <div className='input-group d-flex flex-row justify-content-between'>
                    <div className='col-12 col-lg-8 mb-3'> 
                        <Option>
                        <div className = "row mb-3">
                        <div className = 'col-12 col-lg-6 mb-3 mt-2'>

                    {/* Icon for infobox on medium to small devices */}
                        <div className='d-flex flex-row justify-content-end'>
                            <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step1InputForm5"></i>
                        </div>

                            <Title className="text-md-start ">Leveransperiod</Title>
                            <VBInput          
                                type={'date'}
                                label={<h6>Upphämtningsdatum</h6>}
                                onChange={(date) => {
                                let inpt = this.delivYear.current;
                                inpt.setState({
                                    value: (1900 + date.getYear()).toString()
                                });
                                inpt.props.removeError(inpt.props.name);
                                setTimeout(() => {
                                    inpt.getOkClass();
                                }, 20);
                                let data = {}
                                data[inpt.props.name] = (1900 + date.getYear()).toString();
                                setTimeout(() => {
                                    this.props.send_ad_data(data);
                                },80);
                            }}
                            />
                            </div>
                            <div className='col-12 col-lg-6' style={{marginTop:"2rem"}}>
                            <VBInput          
                                type={'number'}
                                label={<h6>Leveransår</h6>}
                                validation={(val) => {
                                if (Number(val)>=(1900 + new Date().getYear())){
                                    return false;
                                }else{
                                    return "Detta år har redan passerat";
                                }
                                }}
                                />
                            </div>  
                        </div> 
                    </Option> 
                    </div>
                
                {/* info box content */}
                    <AdflowInfo id="Step1InputForm5" aria="Step1InputForm5"> 
                        <Paragraph>
                        <p class='lh-base px-2 py-2'>
                            <strong>Upphämtningsdatum: </strong>Datum när eventuellt vald köpare kan börja hämta virket <br/><br/>
                            <strong>Leveransår: </strong> Vilket år kommer du leverera virket <br/>
                            </p>
                        </Paragraph>
                        </AdflowInfo>
                    </div>
                {/* end of 5th input form */}
            </div>;
        }

    }

}

export default AdFlow1;