
import BankContactPerson from "./bankContactPerson";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/vbDropdown";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import React from "react";

const styles = {
  searchContainer: {
    webkitBoxShadow: "17px 16px 32px 6px rgba(158,158,158,0.74)",
    boxShadow: "17px 16px 32px 6px rgba(158,158,158,0.74)",
    border: "none",
    backgroundColor: "#eeeeee",
  },
};

class ForestCatalogue extends React.Component{
  constructor(props){
    super(props);
    if (props.municipality.hasOwnProperty("name") || props.service.hasOwnProperty("name")){
      this.state={
        municipalitySelected:{muniValue:props.municipality.id, muniName:props.municipality.name},
        serviceSelected:{serviceValue:props.service.id, serviceName:props.service.name},
        persons: null,
        isLoading:false
      }
    }
    else{
      this.state={
        municipalitySelected: {},
        serviceSelected: {},
        persons: null,
        isLoading:false
      }
    }
  }


  async getData (){
    try {
      this.setState({isLoading:true})
      const response= await fetch(
        `/forest-catalogue-api/${this.state.municipalitySelected.muniValue + '/' + this.state.serviceSelected.serviceValue}`
      );
      console.log(response)

      if (response.ok) {
        let personsData = await response.json();
        let url = '/hitta-skogsbank/' + this.state.municipalitySelected.muniName + '/' + this.state.serviceSelected.serviceName;
        history.pushState({}, '', url)
        
        this.setState({ persons:personsData, isLoading:false})
        
        console.log("persons state",(this.state.persons))

        $("body").animate(
          {
            scrollTop: $(".card-container").offset().top,
          },
          "slow"
        );
      } else {
        this.setState({isLoading:true})
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount(){
    if (this.state.municipalitySelected.muniValue || this.state.serviceSelected.serviceValue){
      this.getData();
    }
  }

  render(){
    return (
      <div>
        <div
          className="container-fluid bg-white pb-5 m-0 p-0"
          id="forest-catelog"
        >
          <div className="container bg-white pt-5 pb-5">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <h1 className="fw-bolder big blue">Hitta skogsbank</h1>
                <h2 className="text-muted pt-4" style={{"fontSize": "1.5rem"}}>
                  Höj lönsamheten i ditt skogsbruk med rätt bank
                </h2>
                <p className="pt-3">
                  Hos bankerna finns lång erfarenhet och mycket kompetens om skog
                  som kan hjälpa dig höja lönsamheten i ditt skogsbruk. Hitta rätt
                  bankkontakt genom att välja kommun och klicka på "hitta
                  bankkontakt"
                </p>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3 d-flex  justify-content-center align-items-center">
                <div className="col-12">
                  <div className={`d-flex flex-column card align-items-center py-4 py-md-5 px-lg-3`} style={styles.searchContainer} >
                    <div class="d-flex flex-column flex-sm-column flex-md-row justify-content-between">
                    <div className="my-auto mx-auto">
                      <div className="px-3 w-100" >

                      {/* municipality input field & dropdown*/}
                       <VBDropdown
                          errors={{}}
                          valueChange={(value, label) => {
                            this.setState({
                              "municipalitySelected":{
                                muniValue:value,
                                muniName:label
                              }
                            });
                          }}
                          ad_data={{muniValue:this.state.municipalitySelected.muniValue}}
                          options={this.props.munis}
                          name="muniValue"
                          title="Välj kommun"
                          disable_ad_data={true}
                        />
                      </div>
                    </div>
                   {/* service input field & dropdown*/}
                    <div className="my-auto mx-auto">
                      <div className="px-3 w-100">
                        <VBDropdown
                          errors={{}}
                          valueChange={(value, label) => {
                            this.setState({
                              "serviceSelected":{
                                serviceValue:value,
                                serviceName:label
                              }
                            });
                          }}
                          ad_data={{serviceValue:this.state.serviceSelected.serviceValue}} 
                          options={this.props.services}
                          name="serviceValue"
                          title="Välj service"
                          disable_ad_data={true}
                        />
                      </div>
                    </div>
                  </div> 
                  <div className="mt-4 px-5">
                      <button
                        className="btn btn-blue btn-fill py-1"
                        onClick={this.getData.bind(this)}
                        type="button"
                        disabled={(!this.state.municipalitySelected.muniValue || !this.state.serviceSelected.serviceValue) }
                          >
                          Hitta bankkontakt
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact person Card component */}
        {this.state.isLoading ? (
          <VBLoadingScreen show_loading={this.state.isLoading} />
        ) : (
          <div>
            {this.state.persons ? (
              <div className="container-fluid m-0 pb-3 p-0 pt-3 bg-whiteoff">
                <BankContactPerson person={this.state.persons} static_path={this.props.static_path} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}


export default ForestCatalogue;