import React from "react";
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import { Title, Option, Paragraph } from './AdFlow1';
import AdflowInfo from './AdflowInfo';


let fields = [
   {value: 'Tall', label: 'Tall'},
   {value: 'Gran', label: 'Gran'},
   {value: 'default', label: 'Löv'},
   {value: 'Al', label: 'Al'},
   {value: 'Asp',label: 'Asp'},
   {value: 'Björk',label: 'Björk'},         
   {value: 'Bok', label: 'Bok'},
   {value: 'Ek', label: 'Ek'}
];

let SI_INDEX_ALTERNATIVES= ['B10', 'B14', 'B18', 'B22', 'B26', 'B30', 'G10', 'G12', 'G14', 'G16',
'G18', 'G20', 'G22', 'G24', 'G26', 'G28', 'G30', 'G32', 'G34', 'G36',
'G38', 'T10', 'T12', 'T14', 'T16', 'T18', 'T20', 'T22', 'T24', 'T26',
'T28', 'T30',]


let si_index = SI_INDEX_ALTERNATIVES.map((si) => {return {'label': si, 'value': si}});


class AdFlow3 extends React.Component {
   constructor(){
      super();
      
      this.tot_m3fub = React.createRef();
      this.tot_m3sk = React.createRef();

      this.state = {
         multiplier: 0,
         tgl: {},
         visible_trees: [
            {'label':'Tall', 'value':'Tall'},
            {'label':'Gran','value':'Gran'},
            {'label':'Löv', 'value': 'default'}
            ],
         ej_obligatorisk: [],
         sections: [],
         hectares: '',
         department_name: '',
         volume:[],

      };
   }

   // addSection(event){
  
   //   let {multiplier} = this.state;

   //   if (input === 1){
   //    multiplier = 100;
   //   }else if (input === 10){
   //    multiplier = 10;
   //   }else if(input === 100){
   //    input = 1;
   //   }else{
   //       this.setState({
   //          tgl_error: "Du har anget en felaktig TGL"
   //      });
   //      return;

   //    // this.setState({
   //    //    multiplier
   //    // });
   //   }

   // }
   addSection(){
      this.state.sections.push({"volume": this.state.volume, "tgl":this.state.tgl, "hectares": this.state.hectares,
       "department_name": this.state.department_name, "ej_obligatorisk": this.state.ej_obligatorisk});
      this.setState({"sections":this.state.sections});
      
     
   }
   

   saveTgl(name, val){
      let new_val = {}
      new_val[name] = val;
      this.setState({"tgl":{...this.state.tgl, ...new_val}})
   }


   removeSection = (e) =>{
      var section = this.state.sections.filter((item, i) => i != e.target.id)
      this.setState({sections: section});
      console.log(e.target.id)
    }


   render() {

   if (this.props.init_load){
        return null;
      }else{ 
      return <div>
   {/* 1st input form */}
      <div className='input-group d-flex flex-row justify-content-between'>
            <div className='col-12 col-lg-8'> 
            <Option>
            <div className = "row mt-2">
            
            {/* Icon for infobox on medium to small devices */}
               <div className='d-flex flex-row justify-content-end'>
                  <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step3InputForm1"></i>
               </div>

               <Title className="text-md-start">Virkesinformation</Title>
               <div className = "col-12 col-lg-12 mt-4">
                     <VBInput 
                        type={"text"}
                        onChange={(value) => {this.setState({department_name: value}) }}
                        label={<h6>Ange avdelningens namn</h6>}
                        placeholder= "Exempelvis Avdelning 34"
                        
                     />
                  <div className = "col-12 col-lg-12 mb-1 mt-2">
                     <VBInput
                        type={"number"}
                        onChange={(value) => {this.setState({hectares: value}) }}
                        label={<h6>Ange avdelningens areal</h6>}
                        placeholder="ha"
                        
                     />
                  </div>
                  <div className = "col-12 col-lg-12 mb-1 mt-2">
                     <VBDropdown
                        type={"number"}
                        options={si_index}
                        onChange={(vals) => {this.setState({ej_obligatorisk: vals})}} 
                        placeholder="Ej obligatorisk"
                        label = {<h6>Ange avdelningens ståndortsindex</h6>}
                     />
                  </div>

                     {this.state.visible_trees.map((itm) => {
                     return (<div className="col-12 col-lg-12 mb-1 mt-2">
                     <VBInput 
                        name={itm.value} 
                        placeholder={itm.label} 
                        suffix={itm.label} 
                        type={"number"} 
                        onChange={(val) => {this.saveTgl(itm.value, val)}} 

                     />
                     </div>);
                     })
                     }

                     <div className = "col-12 col-lg-12 mb-1 mt-2">   
                     <VBMultiOption
                     type={"checkbox"}
                     options={fields}
                     initial={[
                        {'label':'Tall', 'value':'Tall'},
                        {'label':'Gran','value':'Gran'},
                        {'label':'Löv', 'value': 'default'}
                        ]}
                     label = {<h6>Lägg till fler trädslag</h6>}
                     onChange={(vals) => {this.setState({visible_trees:vals})}} 
                     />

                  </div>
                  </div> 
               </div>
               
            <div className = "row mt-5 mb-2">
               <div className = "col-12 col-lg-6">
                     <VBInput      
                     type={'number'}
                     onChange={(num) =>{this.setState({volume: num.replace(",", ".")})
                        this.tot_m3fub.current.setState({
                           value: Math.round(num.replace(",", ".") * 0.83),
                        });
                        }}
                     ref={this.tot_m3sk}
                     suffix="m³sk"
                     label={<h6>Ange Uttag inkl. Tillväxt (m³sk eller m³fub)</h6>}
                     />
                  </div>

               <div className="col-12 col-lg-6 mt-4">
                     <VBInput      
                     type={'number'} 
                     suffix="m³fub"
                     onChange={(num) =>{this.setState({volume: num.replace(",", ".")})
                        this.tot_m3sk.current.setState({
                           value: Math.round(num.replace(",", ".") / 0.83),
                        });
                      }}
                     ref={this.tot_m3fub}
                     />
               </div>  
            <div class="d-grid gap-2 d-md-block mt-2" style={{marginTop:"0.5rem"}}>
               <button class="btn btn-blue" onClick={this.addSection.bind(this)} type="button">Lägg till avdelning</button>
            </div>
         
         <div class= "sections-container" style={{marginTop:"2rem"}}>
            
             {this.state.sections.length> 0 ?
               <h6>Avdelningar </h6> 
              : null} 
            <div class="col-sm-4 mt-2 shadow p-3 mb-5 bg-body rounded border border-whiteoff border-2">
            
            <div className='d-flex flex-row justify-content-end' onClick={(e)=>this.removeSection(e)}>
                  <i class="bi bi-x" style={{color: "red" }}></i>
            </div>
            
               {this.state.sections.map((sct) => {
                  return <div>
                     Department: {sct.department_name}
                     ha: {sct.hectares}
                     TGL: {Object.entries(this.state.tgl).map((itm) =>{ return itm[0] + ":" + itm[1] })}
                     Volume:  {sct.volume}
                     Trees:  {sct.ej_obligatorisk.label}
                  </div>;
                  })
                  } 
            </div>
         
         </div>

      </div>
   </Option> 
</div>
      
      {/* info box content */}
         <AdflowInfo id="Step3InputForm1" aria="Step3InputForm1"> 
            <Paragraph>
            <p class="lh-base px-2 py-2">
               Om du kan, utgå då från din skogsbruksplan, mestadels av denna information finns under avdelningsbeskrivningen. Har du inte en skogsbruksplan kan du ändå fylla i formuläret, behöver du hjälp ring oss på <a href="tel:0046339944">08-339944</a> eller boka en tid <a data-track="true" data-eventName="Clicked boka genomgång" href="https://virkesborsen.typeform.com/to/MwS6W2" target="_blank">här</a> så hjälper vi dig!<br/><br/>
               <strong>Avdelningsnamn:</strong> Namnet på den aktuella avdelningen (Exempel avd 34) här är det fritt att kalla avdelningen vad som helst.<br/><br/>
               <strong>Avdelningens areal:</strong> Areal på den aktuella avdelningen anges i hektar.<br/><br/>
               <strong>Ståndortsindex:</strong> Finns också i skogsbruksplanen, detta fält är inte obligatoriskt.<br/><br/>
               <strong>Trädslagsfördelning (TGL): </strong>
               TGL eller trädslagsblandning anger fördelningen mellan tall, gran, löv eller andra trädslag i din skogsbruksplan. Om det exempelvis står 811 så betyder det att avdelningen har 80% tall, 10% gran och 10% löv. Står det istället X00 så betyder det att det är 100% tall. Notera att summan alltid måste bli 10 eller 100.<br/><br/>
               Har du några andra trädslag klickar du på ”Lägg till fler trädslag” för att fylla procentandelen för det trädslaget också. <br/><br/>
               <strong>Uttag inkl. Tillväxt:</strong> Den volym på den avledning som skall tas ut vid avverkningen eller gallringen står oftast under kolumnen ”Uttag ink tillväxt” i skogsbruksplanen. Vi kan även hjälpa till att ta fram detta. behöver du hjälp ring oss på <a href="tel:0046339944">08-339944</a> eller boka en tid <a data-track="true" data-eventName="Clicked boka genomgång" href="https://virkesborsen.typeform.com/to/MwS6W2" target="_blank">här</a> så hjälper vi dig!<br/><br/>
               </p>
               </Paragraph>
            </AdflowInfo>
         </div>
      {/* end of input form*/}
         </div>;
      }

   }

}



export default AdFlow3;
