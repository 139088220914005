
import React from 'react';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import { Title, Option, Paragraph } from './AdFlow1';
import AdflowInfo from './AdflowInfo';

class AdFlow4 extends React.Component {

    render() {
      if(this.props.init_load){
        return null;
      } else{
      let yesno = [{
          "id": "true",
          "label": "Ja"
      },
      {
          "id": "false",
          "label": "Nej"
      },
      {
          "id": "null",
          "label": "Vet ej"
      }];

    return <div>
    {/* 1st input form */}
      <div className='input-group d-flex flex-row justify-content-between'>
        <div className='col-12 col-lg-8'> 
          <Option>
            <div className = "row mb-3 mt-2">
            
            {/* Icon for infobox on medium to small devices */}
             <div className='d-flex flex-row justify-content-end'>
                  <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step4InputForm1"></i>
              </div>

            <Title className="text-md-start">Vill du ha någon skogsvård i samband med din avverkning?</Title>
              <div className = "col-12 col-lg-12 mt-3 mb-3">
          
                      <VBDropdown
                          label = {<h6>Markberedning</h6>}
                          options={yesno}/>
                      <VBDropdown
                          label = {<h6>Återplantering</h6>}
                          options={yesno}/>
                      <VBDropdown
                          label = {<h6>Förröjning</h6>}
                          options={yesno}/>
                    </div> 
                  </div>
                </Option> 
              </div>
            
            {/* Info box content */}
              <AdflowInfo id="Step4InputForm1" aria="Step4InputForm1"> 
              <Paragraph>
               <p class="lh-base px-2 py-2">
                  <strong>Skogsvård: </strong>Om du ska utföra någon skogsvård i samband med din avverkning/gallring och vill ha offert på utförandet av skogsvården väljer du Ja på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket.Om du gjort fel kan du klicka på ”Rensa markeringar” för att tömma kartan.
                </p>
               </Paragraph>
            </AdflowInfo>
          </div>
        {/* end of 1st input form */}

        {/* 2nd input form */}
          <div className='input-group d-flex flex-row justify-content-between'>
            <div className='col-12 col-lg-8'> 
              <Option>
                <div className = "row mt-2" >
              
              {/* Icon for infobox on medium to small devices */}
                <div className='d-flex flex-row justify-content-end'>
                  <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step4InputForm2"></i>
                </div>

                <Title className="text-md-start ">Övrig information gällande skogsvård</Title>
                    <div className = "col-12 col-lg-12 mb-3">
                      <VBTextarea

                      />
                    </div> 
                  </div>
                </Option> 
            </div>

          {/* info box content */}
            <AdflowInfo id="Step4InputForm2" aria="Step4InputForm2"> 
              <Paragraph>
               <p class="lh-base px-2 py-2">
                  <strong>Övrig information gällande skogsvård:</strong> Här kan du ange hur du vill att förröjningen skall utföras, hur många plantor du vill ska återplanteras eller annan viktig information runt din skogsvård.
                </p>
              </Paragraph>
            </AdflowInfo>
          </div>
        {/* end of 2nd form input */}
      </div>;
      }

  }

}



export default AdFlow4;