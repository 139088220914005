import React from "react";
import AdFlow1 from "./AdFlow1";
import AdFlow2 from "./AdFlow2";
import AdFlow3 from "./AdFlow3";
import AdFlow4 from "./AdFlow4";
import AdFlow5 from "./AdFlow5";


class AllSteps extends React.Component{
    constructor(props){
        super(props);

        this.state = { 
            activeTab: 1 //selected tab
         };  
         
        this.onChangeTab = this.onChangeTab.bind(this);

    }
  
      onChangeTab = (id) =>e => {
      e.preventDefault();
      this.setState({
          activeTab: id
       
      })
      this.scrollTop()
    }


    scrollTop(){
    setTimeout(() => {window.scrollTo(
        {top: 0, behavior: 'smooth'});
    },100);
    }

  

    activeTab(id){
        return 'tab-pane fade' +  (this.state.activeTab == id ? ' active show' : '')
    }

render(){
      
    return <div>
    <ul class="nav nav-tabs nav-justified mb-5" style={{fontSize:"14px"}}>
       <li class="nav-item">
          <a onClick={this.onChangeTab(1).bind(this)} class="nav-link" >Step 1</a> 
        </li>
        <li class="nav-item">
          <a onClick={this.onChangeTab(2).bind(this)} class="nav-link" >Step 2</a>
        </li>
        <li class="nav-item">
          <a  onClick={this.onChangeTab(3).bind(this)} class="nav-link"  >Step 3</a>
        </li>
        <li class="nav-item">
          <a onClick={this.onChangeTab(4).bind(this)} class="nav-link" >Step 4</a>
        </li>
        <li class="nav-item">
          <a onClick={this.onChangeTab(5).bind(this)} class="nav-link" >Step 5</a>
        </li>
      </ul>

    <div class="tab-content">
        <div className= {this.activeTab(1)} role="tabpanel" id="1">
            <AdFlow1 {...this.props}/>
        <div class="d-flex flex-row mt-4">
        <div class="col d-flex justify-content-end" style={{marginRight:"29rem"}}> 
            <button class="btn btn-blue" type="button">Spara utkast</button>
            <button class="btn btn-primary text-light ms-2" onClick={this.onChangeTab(2).bind(this)} type="button">Nästa</button>
        </div>
        </div>
    </div> 

    <div className= {this.activeTab(2)} role="tabpanel" id="2">
        <AdFlow2 {...this.props}/>
        <div class="d-flex flex-row mt-4">
        <div class="col d-flex justify-content-start">
          <button class="btn btn-primary text-light"  onClick={this.onChangeTab(1).bind(this)}type="button">Föregående</button>
        </div>
        <div class="col d-flex justify-content-center" style={{marginRight:"19rem"}}>
          <button class="btn btn-blue" type="button">Spara utkast</button>
          <button class="btn btn-primary text-light ms-2" onClick={this.onChangeTab(3).bind(this)} type="button">Nästa</button>
        </div>
        </div>
       </div>

        <div className={this.activeTab(3)} role="tabpanel" id="step3">
           <AdFlow3/>
           <div class="d-flex flex-row mt-4">
        <div class="col d-flex justify-content-start">
          <button class="btn btn-primary text-light"  onClick={this.onChangeTab(2).bind(this)} type="button">Föregående</button>
        </div>
        <div class="col d-flex justify-content-center" style={{marginRight:"19rem"}}>
          <button class="btn btn-blue" type="button">Spara utkast</button>
          <button class="btn btn-primary text-light ms-2" onClick={this.onChangeTab(4).bind(this)} type="button">Nästa</button>
        </div>
        </div>
        </div>

        <div className={this.activeTab(4)} role="tabpanel" id="step4">
           <AdFlow4/>
           <div class="d-flex flex-row mt-4">
        <div class="col d-flex justify-content-start">
          <button class="btn btn-primary text-light"  onClick={this.onChangeTab(3).bind(this)} type="button">Föregående</button>
        </div>
        <div class="col d-flex justify-content-center" style={{marginRight:"19rem"}}>
          <button class="btn btn-blue" type="button">Spara utkast</button>
          <button class="btn btn-primary text-light ms-2" onClick={this.onChangeTab(5).bind(this)} type="button">Nästa</button>
        </div>
        </div>
        </div>

        <div className={this.activeTab(5)} role="tabpanel" id="step5">
           <AdFlow5/>
           <div class="d-flex flex-row mt-4">
        <div class="col d-flex justify-content-start">
          <button class="btn btn-primary text-light"  onClick={this.onChangeTab(4).bind(this)}type="button">Föregående</button>
        </div>
        <div class="col d-flex justify-content-center" style={{marginRight:"19rem"}}>
          <button class="btn btn-blue" type="button">Spara utkast</button>
          <button class="btn btn-primary text-light ms-2" type="button">Nästa</button>
        </div>
        </div>
        </div>

      </div>
    </div>

  }

}


export default AllSteps;