
import React from 'react';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/vbLeaflet';
import { Title, Option, Paragraph } from './AdFlow1';
import AdflowInfo from './AdflowInfo';


class AdFlow2 extends React.Component {
    constructor(){
        super();
        this.state={
          "seen_delete_notice": false
        }
        this.map = React.createRef();

        
    }
    render() {

    if (this.props.init_load){
        return null;
      }else{ 
      return <div>
    {/* 1st input form  */}
     <div className='input-group d-flex flex-row justify-content-between'>
      <div className='col-12 col-lg-8'> 
       <Option>
          <div className = "row mt-2" >
          
          {/* Icon for infobox on medium to small devices */}
          <div className='d-flex flex-row justify-content-end'>
              <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step2InputForm1"></i>
          </div>

          <Title className="text-md-start ">Vem är ansvarig för avverkningsanmälan?</Title>
              <div className = "col-12 col-lg-12 mb-3 mt-3">
                  <VBDropdown
                    options={   [
                      {
                          "id": "seller",
                          "label": "Jag"
                      },
                      {
                          "id": "buyer",
                          "label": "Köparen"
                      },
                      {
                          "id": "exists",
                          "label": "Finns Redan"
                      }
                      ]}/>
                </div> 
              </div>
            </Option>
          </div>
        
        {/* info box content */}
          <AdflowInfo id="Step2InputForm1" aria="Step2InputForm1"> 
              <Paragraph>
                <p class="lh-base px-2 py-2">
                <strong>Jag:</strong> Du som skogsägare utför själv avverkningsanmälan via skogsstyrelsen.<br/><br/>
                <strong>Köparen:</strong> Personen som du eventuellt väljer som köpare upprättar avverkningsanmälan åt dig.<br/><br/>
                <strong>Finns redan:</strong> Om en avverkningsanmälan redan finns väljer du detta alternativ.
                </p>
                </Paragraph>
              </AdflowInfo>
          </div>
        {/* end of 1st input form  */}


    {/* 2nd input form */}
        <div className='input-group d-flex flex-row justify-content-between'>
          <div className='col-12 col-lg-8'> 
            <Option>
              <div className = "row mt-2 mb-3" >
              
              {/* Icon for infobox on medium to small devices */}
              <div className='d-flex flex-row justify-content-end'>
                  <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step2InputForm2"></i>
              </div>

              <Title className="text-md-start ">Kommun och Karta</Title>
              <div className = "col-12 col-lg-12">
                    <VBDropdown
                        label = {<h6>I vilken kommun ligger skogen?</h6>}
                        options={this.props.municipalities} 
                        
                      />
                    <div style={{height: '500px', marginTop:"2rem"}}>
                      <VBLeaflet
                        onChange={(e, draw) => {this.setState({geoLeafletConvert: draw.toGeoJSON()});}}
                        initialGeojson={this.state.geoLeafletConvert}
                        basemapName="Mapbox Plain"
                        setView={this.state.latitude && this.state.longitude ? [this.state.latitude, this.state.longitude] : [59.216366, 15.161133]}
                        setZoomView={this.state.latitude && this.state.longitude ? 13 : 6}
                        enableDrawControl={true}
                        enableLayers={true}
                      />
                    </div>
                  </div> 
                </div>
              </Option> 
          </div>
         
         {/* Info box content */}
          <AdflowInfo id="Step2InputForm2" aria="Step2InputForm2"> 
              <Paragraph>
                <p class="lh-base px-2 py-2">
                  <strong>Kommun:</strong> Välj i vilken kommun din skog står i.<br/><br/>
                  <strong>Placera markör:</strong> Här kan du placera en markör över området där skogen finns eller för att markera avlägg.<br/><br/>
                  <strong>Hämta avverkningsanmälningar:</strong> Om du redan har avverkningsanmälan klickar du på hämta avverkningsanmälningar, zoomar in i kartan och väljer den/dom avverkningsanmälningarna för den aktuella annonsen.<br/><br/>
                  <strong>Rita manuellt:</strong> Här kan du själv rita in området som skall avverkas eller gallras. Du kan rita in flera områden direkt.<br/><br/>
                  <strong>Ladda upp shape:</strong> Om du har en eller flera shape-filer på området som skall avverkas kan du ladda upp dem här (En fil åt gången). Om du har separata .shp, .dbf, .prj, .cpg, .shx filer slå ihop dom till en .zip fil och ladda upp den.<br/><br/>
                  <strong>Rensa markeringar:</strong> Om du gjort fel kan du klicka på ”Rensa markeringar” för att tömma kartan.
                </p>
              </Paragraph>
            </AdflowInfo>
          </div>
        {/* end of 2nd input form */}
       </div>;
      }

    }

  }


export default AdFlow2;
