
import React from 'react';
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/new/vbDropdown";
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import { Title, Option, Paragraph } from './AdFlow1';
import AdflowInfo from './AdflowInfo';


class AdFlow5 extends React.Component {

  render() {
      if(this.props.init_load){
        return null;
      } else{
    return <div>
  {/* 1st input form */}
    <div className='input-group d-flex flex-row justify-content-between'>
        <div className='col-12 col-lg-8'> 
          <Option>
            <div className = "row mt-2 mb-3">
            
            {/* Icon for infobox on medium to small devices */}
              <div className='d-flex flex-row justify-content-end'>
                <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step5InputForm1"></i>
              </div>

            <Title className="text-md-start ">Övriga frågor</Title>
              <div className = "col-12 col-lg-12 mt-4">
                    <VBDropdown
                      label = {<h6>Hur vill du signera kontraktet?</h6>}
                      options={[
                        {
                            "id": "digital",
                            "label": "Digitalt"
                        },
                        {
                            "id": "paper",
                            "label": "Papper"
                        }
                      ]}/>
                  <div  style={{marginTop: "1rem"}}>
                  <VBDropdown
                      label = {<h6>Vill du sälja GROT?</h6>}
                      boolMode={true}
                      options={[
                        {
                            "id": "true",
                            "label": "Ja"
                        },
                        {
                            "id": "false",
                            "label": "Nej"
                        },
                        {
                            "id": "null",
                            "label": "Vet ej"
                        }
                      ]}/>
                    </div>  

                  <div  style={{marginTop: "1rem"}}>
                  <VBDropdown
                      label = {<h6>Är skogen certifierad?</h6>}
                      options={[
                        {
                            "id": "PEFC",
                            "label": "PEFC"
                        },
                        {
                            "id": "FSC",
                            "label": "FSC"
                        },
                        {
                            "id": "both",
                            "label": "Dubbel"
                        },
                        {
                            "id": "no",
                            "label": "Nej"
                        },
                        {
                            "id": "donno",
                            "label": "Vet ej"
                        }
                      ]}/>
                  </div>
                  <div  style={{marginTop: "1rem"}}>
                  <VBDropdown
                        label = {<h6>Är detta redan avverkat?</h6>}
                        options={[
                            {
                              "id": "false",
                              "label": "Ja"
                          },
                          {
                              "id": "true",
                              "label": "Nej"
                          }
                        ]}/>
                  </div>
                </div> 
              </div>
            </Option> 
          </div>
        
        {/* info box content */}
        <AdflowInfo id="Step5InputForm1" aria="Step5InputForm1"> 
          <Paragraph>
          <p class="lh-base px-2 py-2">
              <strong>Kontrakt: </strong><br/> Om du får in anbud som du är nöjd med och väljer köpare på Virkesbörsen, tar vi fram ett avtalsförslag som du kan signera (digitalt med BankID eller papperskontrakt, fysiskt). Det går lika bra att skriva ett eget avtal med köparen i efterhand också. Notera tar du in och väljer anbud utanför Virkesbörsen kan vi INTE hjälpa dig med avtalet.<br/><br/>
                <strong>Vill du sälja GROT?</strong><br/>
                Om du väljer Ja här så får du även ett pris på biobränslet som faller ut när du avverkar. Vill du inte sälja biobränslet i samband med din avverkning väljer du nej.<br/><br/>
                <strong>Är skogen certifierad?</strong><br/>
                Om din skog är certifierad välj då antingen PEFC, FSC alternativt dubbel om du har båda.
              </p>
              </Paragraph>
          </AdflowInfo>
        </div>
      {/* end of start input form 1 */}

      {/* 2nd input form*/}
        <div className='input-group d-flex flex-row justify-content-between'>
          <div className='col-12 col-lg-8'> 
            <Option>
              <div className = "row" >
              
              {/* Icon for infobox on medium to small devices */}
                <div className='d-flex flex-row justify-content-end'>
                  <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step5InputForm2"></i>
                </div>

                <Title className="text-md-start ">Övrig information gällande skogsvård</Title>
                <div className = "col-12 col-lg-12 mb-3 mt-1">
                  <VBTextarea

                  />
              </div> 
            </div>
          </Option> 
        </div>

        <AdflowInfo id="Step5InputForm2" aria="Step5InputForm2"> 
          <Paragraph>
          <p class="lh-base px-2 py-2">
                <strong>Övrig information:</strong><br/> Här anger du all övrig information som är bra för köparen att känna till. Detta kan exempelvis vara vilka bilvägar man får köra på, kod till vägbom, om du önskar förskott, om skogen är snitslad/bandad eller annan viktig information som hjälper köparen. Om det inte finns någon övrig information så lämna denna ruta tom.
            </p>
          </Paragraph>
          </AdflowInfo>
        </div> 
      {/* end of 2nd input form */}
      
      {/* 3rd input form */}
        <div className='input-group d-flex flex-row justify-content-between'>
          <div className='col-12 col-lg-8'> 
            <Option>
              <div className = "row" >
            
            {/* Icon for infobox on medium to small devices */}
              <div className='d-flex flex-row justify-content-end'>
                <i class="bi bi-info-circle-fill blue d-block d-sm-block d-md-block d-lg-none" data-bs-toggle="modal" data-bs-target="#Step5InputForm3"></i>
              </div>

              <Title className="text-md-start ">Bilagor</Title>
                <div className = "col-12 col-lg-12 mt-1">
                  <h6>File upload will go here</h6>
                  </div> 
                </div>
            </Option> 
        </div>    

        <AdflowInfo id="Step5InputForm3" aria="Step5InputForm3"> 
          <Paragraph>
          <p class="lh-base px-2 py-2">
                <strong>Ladda upp bilagor:</strong><br/> Här kan du ladda upp samtliga bilagor som rör den aktuella avverkningen/gallring. Exempelvis skogsbruksplan, karta, villkor, stämplingslängder, avverkningsanmälan etc.
                </p>
              </Paragraph>
          </AdflowInfo>
        </div> 
      {/* end of 3rd input form*/}
      </div>;
    }

  }

}



export default AdFlow5;