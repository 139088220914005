import React from 'react';
import {VBRadios} from '../connect';
import {VBInput} from '../connect';
import {VBDatePicker} from '../connect';
import {VBStepButton} from '../connect';

class Step1 extends React.Component {
    constructor(){
        super();
        this.delivYear = React.createRef();
    }

    clear_errors(){
        this.props.removeError("start_time");
        this.props.removeError("end_time");
        this.props.removeError("felling_start_time");
        this.props.removeError("felling_end_time");
    }

    componentDidMount(){
        gtag('event', 'Created ad draft', {});
        gtag("event", "generate_lead", {
            currency: "SEK",
            value: 585
          });
    }

    render() {
        if(this.props.init_load){
            return null;
        } else{
            return <div class="step-section" data-step="1" style={this.props.step == 1 ? {"display":"block"} : {"display":"none"}}>
                
                <div class="input-grouping">
                    <div class="inputs">
                        <VBRadios
                            name="arrangement"
                            title="Vad vill du göra?"
                            helptText="Välj hur du vill ta in anbuden"
                            options={this.props.arrangement}
                            ad_data={this.props.ad_data}
                            larger={true}
                        />
                        <VBInput
                        inputType="text" 
                        larger={true}
                        name="description_of_property" 
                        ad_data={this.props.ad_data} 
                        title="Fastighetsbeteckning/fastighetsbeteckningar"/>
                </div>
                <div class="inputs-info">
                    <p>
                        <b>Slutavverkning/föryngringsavverkning:</b> Betyder samma sak och innebär att man avverkar beståndet i sin helhet. I skogsbruksplanen brukar områden som skall Slutavverkas vara markerade som S1 eller S2.<br/><br/>
                        <b>Gallring:</b> I skogsbruk innebär att man avverkar vissa träd i ett bestånd men låter majoriteten stå kvar. I skogsbruksplanen brukar områden som skall gallras vara markerade som G1 eller G2.<br/><br/>
                        <b>Övrig avverkning:</b> Har du träd som fallit ner av vinden eller en speciell typ av avverkning som du vill göra väljer du detta alternativ.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>

            <div class="input-grouping">
                <div class="inputs">
                    <VBRadios name="type" 
                    ad_data={this.props.ad_data} 
                    title="Hur vill du sälja ditt virke?" 
                    larger={true}
                    options={this.props.adTypes}
                    />
                </div>
                <div class="inputs-info">
                    <p>
                        <b>Ange den försäljningsform du vill sälja ditt virke på.</b> Är du osäker hjälper vi dig gärna med att förklara vilka olika alternativ som finns.<br/><br/>
                        <b>Avverkningsuppdrag:</b> Är den vanligaste formen att sälja virke på. Här får du antingen anbud som pris per sortiment eller prislista + eventuella tillägg. Kostnaden för att avverka och ta hand om ditt virke får du som en fast avverkningskostnad eller som en lista baserad medelstam.<br/><br/>
                        <b>Leveransrotköp:</b>  Med denna försäljningsform får du ett nettopris inkl. avverkningskostnader oavsett kvalité och sortiment. Exempel 450kr/m3fub.<br/><br/>
                        <b>Leveransvirke:</b> När du eller någon annan avverkar och skogsbolaget köper virket vid bilväg. Här får du också oftast prislista + eventuella tillägg eller fast pris per sortiment.<br/><br/>
                        <b>Rotpost:</b> När skogen är stämplad av en stämplingsförrätare och en stämplingslängd finns. Då får du ett total pris inkl. avverkningskostnad exempel (1 000 000 kr).
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="input-grouping">
                <div class="inputs">
                    <h3>Annonseringperiod</h3>
                    <div className="two-child">
                        <VBDatePicker onChange={() => {this.props.removeError("end_time")}} name="start_time" title="Från" ad_data={this.props.ad_data} inputType="date" hidden={this.props.hidden} />
                        <VBDatePicker onChange={() => {this.props.removeError("felling_start_time")}} name="end_time" title="till" ad_data={this.props.ad_data} inputType="date" hidden={this.props.hidden} />
                    </div>
                </div>
                <div class="inputs-info">
                    <p><b>Annonseringsperiod: </b>Här väljer du hur lång tid din annons ska vara publicerad på Virkesbörsen. Detta är den tid som virkesköparna har på sig att lämna anbud. Vi rekommenderar att annonsen ska ligger uppe i 3-4 veckor så att köparna har tillräckligt med tid. </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
                
            <div class="input-grouping" style={this.props.hidden.indexOf("fellingperiod") > -1 ? {display:"none"} : {display:""}}>
                <div class="inputs">
                    <h3>Avverkningsperiod</h3>
                    <div className="two-child">
                        <VBDatePicker onChange={() => {this.props.removeError("felling_end_time")}} name="felling_start_time" title="Från" ad_data={this.props.ad_data} inputType="date" hidden={this.props.hidden} />
                        <VBDatePicker  name="felling_end_time" title="till" ad_data={this.props.ad_data} inputType="date" hidden={this.props.hidden} />
                    </div>
                </div>
                <div class="inputs-info">
                    <p><b>Avverkningsperiod: </b>Här anger du hur lång tid köparen som du eventuellt väljer har på sig att avverka skogen. Vi rekommenderar 2 till 5 år.</p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="input-grouping" style={this.props.hidden.indexOf("deliverydata") > -1 ? {display:"none"} : {display:""}}>
                <div class="inputs">
                    <h3>Leveransperiod</h3>
                    <div className="two-child">
                        <VBDatePicker name="pickup_date" title="Upphämtningsdatum" ad_data={this.props.ad_data} inputType="date" hidden={this.props.hidden}
                        onChange={(date) => {
                            let inpt = this.delivYear.current;
                            inpt.setState({
                                value: (1900 + date.getYear()).toString()
                            });
                            inpt.props.removeError(inpt.props.name);
                            setTimeout(() => {
                                inpt.getOkClass();
                            }, 20);
                            let data = {}
                            data[inpt.props.name] = (1900 + date.getYear()).toString();
                            setTimeout(() => {
                                this.props.send_ad_data(data);
                            },80);
                        }}
                        />
                        <VBInput ref={this.delivYear} name="delivery_year" title="Leveransår" ad_data={this.props.ad_data} inputType="number" placeholder={"xxxx"} hidden={this.props.hidden} 
                        validation={(val) => {
                            if(Number(val) >= (1900 + new Date().getYear())){
                                return false;
                            } else{
                                return "Detta år har redan passerat";
                            }
                        }}
                        />
                    </div>
                </div>
                <div class="inputs-info">
                    <p>
                        <b>Upphämtningsdatum: </b>Datum när eventuellt vald köpare kan börja hämta virket <br/><br/>
                        <b>Leveransår: </b> Vilket år kommer du leverera virket
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
            </div>
            <div class="btn-container">
                <div class="left">
                     
                </div>
                <div class="right">
                    <button onClick={() => this.props.visible_ad_draft()} class="btn blue large dash">Spara utkast</button>
                    <VBStepButton text="Nästa" increment={1} />
                </div>
			</div>
        </div>;
        }
    }
}

export default Step1;